import { format } from 'date-fns';
import { useHydrated } from 'remix-utils/use-hydrated';

type FormatDateProps = {
	date: Date | string;
	format?: string;
	clientOnly?: boolean;
	className?: string;
};
export function FormatDate(props: FormatDateProps) {
	const { format: formatString, date, className, clientOnly } = props;
	const parsedDate = typeof date === 'string' ? new Date(date) : date;
	const hydrated = useHydrated();

	if (clientOnly) {
		return hydrated ? <span className={className}></span> : null;
	}

	return (
		<span suppressHydrationWarning className={className}>
			{format(parsedDate, formatString ?? 'd-M-yyyy')}
		</span>
	);
}
